#sidebar {
  position: fixed;
  width: 250px;
  height: 100vh;
  top: 0px;
  /* left: 5px; */
  bottom: 50px;
  /* border-radius: 10px; */
  /* background-color: #1A0045; */
  /* background: #72ebee; */
  z-index: 500;
  /* background: #4e1a68; */
  background: linear-gradient(to right,#9B92B7, #D6949F) !important;
}

.logotext {
  height: 180px;
  width: 250px;
  position: fixed;
  top: 30px;
  z-index: 1000;
}

.avatar {
  margin-left: 70px;
  margin-right: 70px;
}

.logotext #atlas {
  margin-left: 55px;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0 !important;
  text-align: left;
  color: #01295F;
}

.logotext #admin {
  font-size: 18px;
  font-weight: 600;
  font-family: Poppins;
  line-height: 27px;
  letter-spacing: 0.08em;
  color: #FFFFFF;
  margin-left: 90px;
  margin-top: -30px !important;
}

.logotext #ecaps {
  color: #fff;
  text-align: center;
  margin-bottom: -10px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
}

.ant-menu {
  /* border-top-right-radius: 90px; */
  /* margin-top: 210px;
  width: 250px;
  padding-top: 40px; */
  font-size: 16px;
}

.ant-menu .ant-menu-item {
  /* margin-top: -18px;
  height: 54px; */
}

.ant-menu-submenu-title {
  margin-top: -14px !important;
  margin-bottom: 10px !important;
  color: #ffffff;
}

.ant-menu-item {
  margin-top: -10px;
}

.ant-menu ul {
  position: relative !important;
  height: auto !important;
}

.link {
  color: #01295F !important; 
  font-weight: 800;
}

.active {
  /* color: #e74c3c !important; */
  color: #01295F !important;
}

.menuItem {
  border: none;
  border-radius: 0;
}

.activeMenuItem {
  border: 1px solid #fff !important;
  border-radius: 15px !important;
  background-color: #fff !important;
}

#sidebar .pro-sidebar-content {
  height: 477px;
  position: fixed;
  top: 30px;
}

.pro-sidebar-header {
  position: fixed;
  width: 100vw;
  z-index: 1000;
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  /* background: #1A0045; */
  /* background: #72ebee; */
  /* background: #C62F6D; */
  background: linear-gradient(to right, #9B92B7, #D6949F);
  height: 100vh;
}

#sidebar .closemenu {
  color: #ffffff;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 5px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

#sidebar .pro-sidebar.collapsed {
  width: 150px;
  min-width: 80px;
}

#sidebar .pro-sidebar-inner {
  /* background-color: #2D105E; */
  background: linear-gradient(274.42deg, #C58BF2 0%, #EEA4CE 124.45%);
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout {
  margin: 0 0 0 5px;
  font-size: 20px;
  padding: 10px 15px;
  text-align: center;
  color: #F0F2F5;
  font-weight: bold;
  border-radius: 20px;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 10px;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout .menu {
  color: rgb(253, 251, 251);
  padding: 10px;
  text-align: center;
  text-decoration: none;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout .navlink {
  text-decoration: none;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout .icon {
  color: rgb(253, 251, 251);
  margin: 10px 0px;
  text-align: center;
  font-weight: bold;
}

#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  color: rgb(252, 248, 248);
  border-radius: 3px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.ant-menu .ant-menu-root .ant-menu-inline .ant-menu-dark {
  height: 100vh;
}

.ant-menu-item-selected {
  border: 1px solid #fff !important;
  border-radius: 15px !important;
  background-color: #fff !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff !important;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #01295F !important;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}

@media only screen and (max-width: 953px) {
  #sidebar {
    height: auto;
  }

  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    height: 400vh;
  }
}

@media only screen and (max-width: 1200px) {
  /* #sidebar .pro-sidebar-content {
    top: -80px;
  } */

  .ant-menu {
    font-size: 12px;
  }

  /* #atlas,
  #admin {
    display: none;
  } */

  .ant-menu-item {
    height: 34px;
  }
}