* {
  font-family: 'Poppins';
  font-style: 'normal';
  margin: 0;
  padding: 0;
}

.main {
  background-color: #FFFFFF;
  width: 98vw;
  height: 98vh;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}