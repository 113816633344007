.card-parent {
    width: 100%;
    padding: 15px 20px;
    border: 1px solid #868686;
    border-radius: 16px !important;
}

.stepper-label {
    font-size: 16px;
    font-weight: 500;
    color: #1A0045;
    line-height: 30px;
    margin-bottom: 0;
}

.verification-status {
    width: 30px;
    height: 30px;
    background-color: #1A0045;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #FFFFFF;
}

.modal-backdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
}

.modal-content-wrapper {
    position: fixed;
    height: 700px;
    width: 900px;
    background: white;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 20px;
    z-index: 500;
}

.stepperHeading {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 36px !important;
    color: #01295F;
    letter-spacing: 0.08em !important;
    text-align: center;
    margin-bottom: 0px;
}

.incomeVerificationHeaderParent {
    background-color: #72ebee;
    color: #01295F;
    height: 60px;
    border-radius: 15px 15px 0 0;
    position: relative !important;
}

.incomeVerificationClose {
    position: absolute !important;
    top: 5px !important;
    right: 5px !important;
}

.incomeVerificationHeading {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 60px !important;
    color: #01295F;
    letter-spacing: 0.08em !important;
    text-align: center;
}

.incomeVerificationSubHeading {
    text-align: center;
    color: #1A0045;
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 25px;
}

.incomeDetailsParent {
    padding: 40px 75px;
}

.incomeVerificationCloseParent {
    position: absolute;
    right: 10px;
    top: 7px;
}

.incomeVerificationOkParent {
    display: flex;
    justify-content: center;
}

.stepSubHeading {
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 23px !important;
    letter-spacing: 0.08em !important;
    text-align: center !important;
    color: #1A0045;
}

.stepperButton {
    background-color: rgb(235, 217, 217) !important;
    width: 250px !important;
    height: 50px !important;
    font-size: 15px !important;
    color: #963460 !important;
    font-weight: 600 !important;
}



/* credit report css */

.creditHeading {
    color: #963460;
    font-size: 24px !important;
    line-height: 36px !important;
    text-align: center;
    font-weight: 600px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
}

.congratHead {
    text-align: center;
    color: #963460;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.08em;
}

.creditHead {
    text-align: left;
    /* margin: 5px !important; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.08em;
    color: #242424;
}

.graph {
    align-items: center;
    margin: 30px;
    margin-top: 10px;
}

.wrapper {
    max-width: 350px;
    margin: auto;
    padding: 40px 0;
}

.speedometerParent {
    /* background-color: aquamarine; */
    width: 100%;
    height: 60%;
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.outercircle {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    width: 100px;
    height: 100px;
    background-color: white;
    box-shadow: 4px 4px 32px 4px rgba(105, 108, 180, 0.16);
    border-radius: 50%;
    position: absolute;
    top: 78px;
    padding: 22px 0;
}

.value {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #1A0045;
    margin-bottom: 0 !important;
}

.text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.08em;
    color: #23D945;
}

.circleBottomHeaderParent {
    width: 100%;
    height: 15%;
    /* background-color: greenyellow; */
    display: flex;
    align-items: center;
}

.circleBottomHeader {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.08em;
    color: #868686;
}

.spec-date {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.08em;
    color: #242424;
}

.equifaxHead {
    text-align: left;
    margin: 5px !important;
    color: #963460;
}

.equiBody {
    padding: 26px !important;
}

.equiChild {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    /* justify-content: space-between; */
}

.equiSecChild {
    display: flex;
    justify-content: space-between;
    padding-left: 182px;
}

.graphButton {
    /* height: 15%; */
    /* background-color: antiquewhite; */
    /* display: flex;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer; */
    margin-left: 223px;
}

.incomeDetailsButton {
    background-color: #01295F !important;
}

.dwnldButton {
    background-color: #ffff !important;
    border: 1px solid blue !important;
    width: 250px !important;
    height: 50px !important;
    font-size: 15px !important;
    color: #963460 !important;
    font-weight: 600 !important;
    /* margin-left: 135px !important; */
    padding: 35px !important;
}

.downloadReportButton {
    height: 40px;
    background: #FFFFFF;
    /* border: 1px solid #5A5DF6; */
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    color: #5A5DF6;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
}

.backButtonParent {
    width: 100%;
    height: 12%;
    /* background-color: greenyellow; */
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Equifax Styles */
.eq-parent-container {
    width: 100%;
    height: 65px;
    display: flex;
    margin-top: 13px;
}

.eq-left-cell {
    width: 15%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.cell-start-end-icon {
    font-size: 20px;
    color: #242424;
    cursor: pointer;
}

.eq-middle-cell {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.eq-middle-cell-left-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.eq-middle-cell-right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.eq-middle-cell-left-container h3,
.eq-middle-cell-right-container h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.08em;
    color: #242424;
}

.eq-middle-cell-left-container h4,
.eq-middle-cell-right-container h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.08em;
    color: #242424;
}

.eq-right-cell {
    width: 15%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #01295F !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #01295F !important;
}

/* Credit Score Stepper */
.credit-score-parent {
    padding: 10px 70px;
    height: 50%;
    display: flex;
    justify-content: space-between;
}

.credit-score-indicator-parent {
    width: 48%;
    height: 35vh;
    border: 1px solid #01295F;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-top: 20px;
}

.credit-score-title {
    position: absolute;
    bottom: 0;
    color: #01295f;
}

.credit-score-details-parent {
    width: 48%;
    height: 35vh;
    border: 1px solid #01295f;
    border-radius: 16px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; */
    position: relative;
    padding: 5px 30px;
    overflow-y: auto;
}

.credit-score-details-elements {
    display: flex;
    justify-content: space-between;
    align-items: center;
}